import React, { useState} from "react";
//import { useNavigate } from "react-router-dom";
import { API , graphqlOperation } from 'aws-amplify'
//import { createTodo, updateTodo, deleteTodo } from '../graphql/mutations'
//import { listTodos } from '../graphql/queries'
import { deleteReply, updateTodo } from '../graphql/mutations'
import {  repliesByDate  } from '../graphql/queries'
//import { listReplies, commentsByPostIDAndDate  } from '../graphql/queries'



import { toast } from "react-toastify";



function RemoveButtonReply( props)  {



   // const navigate = useNavigate();
    function snotify(note) {toast.success(note);};


    //const [commentList, setCommentList] = useState([]);
    //const [comment, setComment] = useState("");

    const [state, setState] = useState("--");
    const [state2, setState2] = useState("--");
    const [state3, setState3] = useState("--");

    function stateChange(f) {
        setState(f);
        props.funct(f);
    //   to set loader
  };

  function stateChange2(f) {
    setState2(f);
    props.funct2(f);
//   to set rcount
};

function stateChange3(f) {
    setState3(f);
    props.funct3(f);
//   to set rcount
};
  


function blank(){};

const getReplies2 = async () => {
    try {
   //   alert("UEFF getReply")
        const replyData = await API.graphql(graphqlOperation(repliesByDate    ,   {todoId: props.todoId ,  sortDirection: 'ASC'}   ));
//       console.log(replyData);
        const replyList2 = replyData.data.repliesByDate.items;
//        console.log('reply list', replyList2);
        stateChange3(replyList2);
       
        stateChange("0");
        // alert('gr done')
    } catch (error) {
        console.log('error on fetching todos', error);
    }
};  


    async function handleRemoveReply() {
        
    


        //Reduce rcount of parent 
        var tmpRcount = props.rcount;
        tmpRcount = tmpRcount - 1;
       
       //alert(tmpRcount);
   
          const result2 =  await API.graphql({
            query: updateTodo,
            variables: { 
                input: {
                    id: props.todoId,
                    rcount: tmpRcount,
                }
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
        });
      







          const result =  await API.graphql({
            query: deleteReply,
            variables: { 
                input: {
                    id: props.replyId,
                  }
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
        });
      


         snotify('Reply Removed'); 
         stateChange("1");
         stateChange2(tmpRcount);
        getReplies2()
       

    };




    

    const handleShowErr = () => {

         alert(props.bot + ' cc-' + props.ccount)
        
        
        };



        var tmpColor = "white";
        var tmpErr = "";
        var tmpHideErr = true;
        var tmpColor2 = 'orange';
  if ( (props.bot).includes("ERROR:") ) {tmpColor="yellow"; tmpErr = "" ;  tmpHideErr = false} else if(props.name==='rkz@toughguys.biz'){ tmpColor2= 'black'; tmpHideErr = false};
        


    if (props.opt2 ===  "1") {

if (props.ccount <= 0 ) {

    if (props.name === props.author) {
        var tmpHide = false;
        var tmpHide2 = true;
        var tmpHide3 = true

    } else if(props.name === "rkz@toughguys.biz" ) {    
        tmpHide = true;
        tmpHide2 = true;
        tmpHide3 = false
    } else {
        tmpHide = true;
        tmpHide2 = false;
        tmpHide3 = true
  
    } 
} else {
    tmpHide = true;
    tmpHide2 = false;
    tmpHide3 = true

}

        } else {


            tmpHide = true;
            tmpHide2 = false;
            tmpHide3 = true
        };




    return (
        <div style={{display:'flex' , flexDirection:'row', minWidth:'10px'}} >
     

            <p hidden = {tmpHide} style={{  color: "#434242" }}>
            <button style={{borderWidth:'0px',backgroundColor:'red', color:'white'}} onClick={handleRemoveReply} >X</button> 
            </p>
            <p hidden={tmpHide2}  style={{ color: "#434242" }}>
            <button  style={{ borderWidth:"0px",     backgroundColor:'inherit', color:'black'}} onClick={blank} >X</button> 
            </p>
            <p hidden = {tmpHide3} style={{  color: "#434242" }}>
            <button style={{borderWidth:'0px', backgroundColor:'grey', color:'white'}} onClick={handleRemoveReply} >X</button> 
            </p>
            <span hidden = {tmpHideErr} style={{backgroundColor:tmpColor }}>{tmpErr} <button  style={{borderWidth:'0px', paddingLeft:'2px', paddingRight:'2px', backgroundColor:tmpColor2, color:'white'}} onClick={handleShowErr} >^</button>  </span> 
        
                </div>
        
    );
};

export default RemoveButtonReply;