import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import HomePublic from "./components/HomePublic";
import Replies from "./components/Replies";
import RepliesPublic from "./components/RepliesPublic";
import './App.css';
import { Amplify, AuthModeStrategyType  } from 'aws-amplify';
import {  useTheme } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import awsExports from './aws-exports';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "./styles.css";
//Amplify.configure(awsExports);

Amplify.configure({
    awsExports,
    DataStore: {
      authModeStrategyType: AuthModeStrategyType.MULTI_AUTH
    }
  })


//function App({signOut,user}) {

function App() {

    useEffect(() => {
        document.title = "no-trolls forum"
      }, []);
    
    
    //function snotify(note) {toast.success(note);};
    //function inotify(note) {toast.info(note)};
    //function enotify(note) {toast.error(note)};
    //function wnotify(note) {toast.warning(note)};

//    <div className='navbarRight' style={{ position:'absolute' , top:'10px' , right:'10px', color:'white'}}>
//{Auth.user.attributes.email}

//</div>


     
//<BrowserRouter>
//<Routes>
//     <Route path='/' element={<Home />} />
//    <Route path='/dashboard' element={<Home />} />
//    <Route path='/data/:id/replies' element={<Replies />} />
//</Routes>
//</BrowserRouter>


const { tokens } = useTheme();

    return (
        <div className="App">
        
            <BrowserRouter>
                <Routes>
                     <Route path='/' element={<HomePublic />} />
                     <Route path='/auth/' element={<Home />} />
                    <Route path='/replies/:id' element={<RepliesPublic />} />
                    <Route path='/auth/replies/:id' element={<Replies />} />
                </Routes>
            </BrowserRouter>
          
            
        

            <ToastContainer
      //position="top-right"
      //progressClassName="toastProgress"
      //bodyClassName="toastBody"
      position="bottom-center"
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      //theme="dark"
      //theme="light"
      theme="colored"
      icon={true}
      >
      </ToastContainer>
  

        </div>
    );
};



//export default withAuthenticator(App);
export default App;
  