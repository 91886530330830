import React from "react";

import { useNavigate } from "react-router-dom";
import { Amplify, Auth} from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import awsExports from '../aws-exports';
import { Header } from "../Header";
import { Footer } from "../Footer";
import { SignInHeader } from "../SignInHeader";
import { SignUpHeader } from "../SignUpHeader";
import { SignInFooter } from "../SignInFooter";
//import { SignUpFooter } from "./SignUpFooter";


Amplify.configure(awsExports);



async function signOut() {
    try {
      await Auth.signOut();
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }


function Nav({user}) {
//    function Nav(){

    const navigate = useNavigate();

//    const onNotificationClick = (notification) =>
  //      navigate(notification.cta.data.url);


       // function back() {
        //    navigate(-1)
         //  
         //  };
               
function blank(){};

function buttonClick(){
    navigate(`/`);
    signOut();
}


    return (
        <nav className='navbar' >
            <h2>no-trolls <span style={{opacity:'.5'}}>(beta)</span>  
            </h2>
            
            
           
            <div className='navbarRight' style={{color:'#f5f5f5', fontSize:'12px'}}>
            
            
            {Auth.user.attributes.email}
        
     
          
         <button className='homeBtn' style={{color:'#f5f5f5', borderRadius:"4px"}} onClick={buttonClick}>sign out</button> 
    
            </div>
            
            
        </nav>
    );
};

//export default Nav;
//export default withAuthenticator(Nav);
export default withAuthenticator(Nav, {

  
  components: {
    
    Header,
    SignIn: {
      Header: SignInHeader,
      Footer: SignInFooter
    },
    SignUp: {
      Header: SignUpHeader,
      
    },
    Footer
  }
  
});