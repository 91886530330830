import {  useTheme, Flex } from "@aws-amplify/ui-react";


export function Header() {
  const { tokens } = useTheme();

  return (
    



<Flex
  direction="row"
  justifyContent="center"
  alignItems = "center"
  textAlign="center"
  backgroundColor="#416288"
color='white'
>
  <span  style={{ fontSize:'32px', textDecoration: 'none' }} >no-trolls</span>
</Flex>
  


  );
}
