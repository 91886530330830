import React, {useState, useEffect} from "react";
//import { useNavigate } from "react-router-dom";
import { API , graphqlOperation } from 'aws-amplify'
//import { deleteTodo } from '../graphql/mutations'
//import { listTodos } from '../graphql/queries'
import { deleteTodo } from '../graphql/mutations'
import { updatesByPostIDandUserAndDate ,  todosByDate  } from '../graphql/queries'
import { toast } from "react-toastify";


function RemoveButton(props) {

    
    
    function snotify(note) {toast.success(note);};




    const [state, setState] = useState("--");
    const [state2, setState2] = useState("--");
    const [lastUpdate, setLastUpdate] = useState("");

    

    function stateChange(f) {
     
        setState(f);
        props.funct(f);

  };

  function stateChange2(f) {
   // alert('sc2');
    setState2(f);
    props.funct2(f);
    stateChange('0');

};

  
  useEffect(() => {


    const fetchLastUpdate = async () => {
        try {
            const updateData = await API.graphql(graphqlOperation( updatesByPostIDandUserAndDate,   {postIDandUser: props.threadId + ' for ' + props.name ,  sortDirection: 'ASC'}   ));
           // console.log(updateData);
           // const todoList = todoData.data.listTodos.items;
           const updateList = updateData.data.updatesByPostIDandUserAndDate.items;
            console.log('update list ' + props.threadId, updateList);

            if (updateList.length === 0 ) { 

                setLastUpdate("1/1/1999")
            } else {

                setLastUpdate(updateList[0].lastUpdate)
            }
            //setTodos(todoList);
           // setLoader('0');
           
        } catch (error) {
            console.log('error on fetching thread last update ' +  props.threadId + ' for ' + props.name , error);
           // createUpdate2() 

        }

  

    };
    






    fetchLastUpdate();
 
}, []);


function blank(){} ;



async function fetchTodos() {
    try {
      //  alert('Ft');
        const todoData = await API.graphql(graphqlOperation( todosByDate,   {type: 'todo' ,  sortDirection: 'ASC'}   ));
     //   console.log(todoData);
       // const todoList = todoData.data.listTodos.items;
       const todoList = todoData.data.todosByDate.items;
        //console.log('todo list', todoList);
        stateChange2(todoList);
       stateChange('0');
    //    setLoader('0');
    } catch (error) {
        console.log('error on fetching todos', error);
    }
};







    async function  handleAddComment() {



        try {
     


            const result = await API.graphql({
                query: deleteTodo,
                variables: { 
                    input: {
                        id: props.threadId,
                      }
                },
                authMode: 'AMAZON_COGNITO_USER_POOLS',
            });
          
         snotify('Thread Removed'); 
         stateChange("1");
         fetchTodos();

      
                } catch (error) {
                  console.log('error5-2 on create metric' , error);
                };
  
                

    };






    const handleShowErr = () => {

        alert(props.bot)
        
        
        };


    



        if (props.opt2 ===  "1") {


    if (props.rcount <= 0 ) {


        if (props.name === props.author) {
            var tmpHide = false;
            var tmpHide2 = true;
            var tmpHide3 = true
    
        } else if(props.name === "rkz@toughguys.biz" ) {    
            tmpHide = true;
            tmpHide2 = true;
            tmpHide3 = false
        } else {
            tmpHide = true;
            tmpHide2 = false;
            tmpHide3 = true
      
        }


    } else {
        tmpHide = true;
        tmpHide2 = false;
        tmpHide3 = true
  
 }


} else {


    tmpHide = true;
    tmpHide2 = false;
    tmpHide3 = true
};


 var tmpColor = "white";
 var tmpErr = "";
 var tmpHideErr = true;
 var tmpHideErr2 = false;
 if ( (props.bot).includes("ERROR:") ) {tmpColor="red"; tmpErr = "" ;  tmpHideErr = false; tmpHideErr2 = true};




 var d1 = new Date(props.threadUpdate);
 var d2 = new Date(lastUpdate);
 var updated = d1.getTime() >= d2.getTime();
if (updated) { var tmpBC = "#00d400" } else {tmpBC = '#416288'};



    return (
        <div style={{backgroundColor:tmpBC}} >
          <div className='likes__container' style={{ boxShadow: '0 0 0px 0px yellow', minWidth:'40px'}} >
     
     
     <p hidden={tmpHide}  style={{ color: "#434242" }}>
     <button  style={{borderWidth:'0px', paddingLeft:'0px', paddingRight:'0px', backgroundColor:'red', color:'white'}} onClick={handleAddComment} >X</button> 
     </p>
     <p hidden={tmpHide2}  style={{ color: "#434242" }}>
     <button  style={{borderWidth:'0px', backgroundColor:'#416288', color:'#416288'}} onClick={blank} >X</button> 
     </p>
     <p hidden={tmpHide3}  style={{ color: "#434242" }}>
     <button  style={{borderWidth:'0px', paddingLeft:'0px', paddingRight:'0px', backgroundColor:'grey', color:'white'}} onClick={handleAddComment} >X</button> 
     </p>
     <span  style={{backgroundColor:tmpColor }}>{tmpErr} <button hidden={tmpHideErr} style={{borderWidth:'0px', paddingLeft:'2px', paddingRight:'2px', backgroundColor:'orange', color:'white'}} onClick={handleShowErr} >^</button>  </span> 
     <span  style={{backgroundColor:tmpColor }}><button hidden={tmpHideErr2} style={{borderWidth:'0px',minWidth:'14px',  paddingLeft:'2px', paddingRight:'2px', backgroundColor:'#416288', color:'#416288'}} onClick={blank} >^</button>  </span> 
<span style={{fontSize:'8px', color:'white', paddingLeft:'10px'}}></span>
 </div>
            <p style={{ color: "#434242" }}>
              
            </p>
        </div>
    );
};

export default RemoveButton;