import React, { useState} from "react";
//import { useNavigate } from "react-router-dom";
import { Auth, API , graphqlOperation } from 'aws-amplify'
import { createComment,  updateMetric } from '../graphql/mutations'
import {  getMetric  } from '../graphql/queries'


import { toast } from "react-toastify";

import Dialog from "@mui/material/Dialog";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
//import Button from "@mui/material/Button";
import {
    Flex,
  //  Heading,
  //  Image,
  //  View,
  } from "@aws-amplify/ui-react";


function AddComment( props)  {


    const [comment2, setComment2] = useState("");
	const [open, setOpen] = React.useState(false);
  
    const handleClickToOpen = () => {
      setOpen(true);
    };
  
    const handleToClose = () => {
		setComment2("");
        setOpen(false);
      };

	
	  const handleSubmitComment2 = (e) => {
        e.preventDefault();
        //👇🏻 calls the function
        stateChange("1");
		//handleAddComment();
        askbot4();

		setOpen(false);  
        
    };


    //const navigate = useNavigate();
    function snotify(note) {toast.success(note);};

    //const [commentList, setCommentList] = useState([]);
    //const [comment, setComment] = useState("");

    const [state, setState] = useState("--");
    const [state2, setState2] = useState("--");
	//const [state3, setState3] = useState("--");
  //const [metrics, setMetrics] = useState([]);  
  //const [metricId, setMetricId] = useState('');

    function stateChange(f) {
        setState(f);
        props.funct(f);
    //   to set loader
  };

  function stateChange2(f) {
    setState2(f);
    props.funct2(f);
//   to set rcount
};
  











const modifyMetric = async (tmp) => {
 // alert('update metric type' + tmp); alert('metric id' + props.metricId)

 //refresh metric
 try {
  var replyData = await API.graphql(graphqlOperation(getMetric    ,   {id: props.metricId }  ));
//     console.log(replyData);


///////////

if (tmp === 'bot4') {
//Incriment bot4
var tmpBot = replyData.data.getMetric.bot4;
 tmpBot = tmpBot + 1;
//setBot4(tmpBot);
try {
const result = await API.graphql(graphqlOperation(updateMetric, {
    input: {
      id: props.metricId,
      bot4: tmpBot,
    }
  }))

    } catch (error) {
      console.log('error5-4 on create metric' , error);
    }


} else if (tmp === 'bot3') {
//Incriment bot3
 tmpBot = replyData.data.getMetric.bot3;
 tmpBot = tmpBot + 1;
//setBot3(tmpBot);
try {
const result = await API.graphql(graphqlOperation(updateMetric, {
    input: {
      id: props.metricId,
      bot3: tmpBot
      
    }
  }))

    } catch (error) {
      console.log('error5-3 on create metric' , error);
    }

} else if (tmp === 'bot2') {
//Incriment bot2
 tmpBot = replyData.data.getMetric.bot2;
 tmpBot = tmpBot + 1;
// setBot2(tmpBot);
try {
const result = await API.graphql(graphqlOperation(updateMetric, {
    input: {
      id: props.metricId,
      bot2: tmpBot

    }
  }))

    } catch (error) {
      console.log('error5-2 on create metric' , error);
    }

} else {
alert('Metric Error 400')
};
//////////

} catch (error) {
  console.log('error on fetching parent metric', error);
};


};
























//function blank(){};




////////////////ASKBOT 2.2 ///////////////////////////////////////
async function askbot2(tmpbot) {
  //  alert('askbot2');
   // alert('askbot-start: ' +  comment2)


if (comment2 === "") {alert('Message blank') } else {

  const genText2 = () => `I want you to tell me if the following message is being mocking, overly sarcastic or is being abusive, harassing, or racist.  Answer in one word if it is, yes or no" 
  
  `
  ;

  var messages=[
   {"role": "system", "content": "you are a helpful assistant that protects users against being trolled, harassed or spammed."},
   {"role": "user", "content": genText2()},
   {"role": "user", "content": comment2},
   
];
 
 //var raw = JSON.stringify({"model": "gpt-3.5-turbo","messages": messages   ,"temperature":0.7});

 var raw = JSON.stringify({"model": "gpt-4-0613","messages": messages   ,"temperature":0.0});


 //alert('askbot-messages: ' +  messages[0])

 
//alert('askbot-pre fetch');


//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

 var requestOptions = {
     method: 'POST',
     headers: {
             'Content-Type': 'application/json',
           'Authorization': 'Bearer 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'
             },
     body: raw,
     redirect: 'follow',
  };
  
  

	await fetch("https://api.openai.com/v1/chat/completions", requestOptions)
  .then((response) => response.text())
  .then((responce) => {
   var obj = JSON.parse(responce);
   var tmp1 = JSON.stringify(obj.choices);
   var tmp2 = tmp1.replace(/\[/g,'');
   var tmp3 = tmp2.replace(/\]/g,'');
   
   var tmp4 = JSON.parse(tmp3);
   var tmp5 = tmp4.message.content;
   var tmp6 = JSON.stringify(tmp5)
   var tmp7 = tmp6.replace(/\\"/g,'');
   var tmp8 = tmp7.replace(/\\n/g,'  ');
   
  //alert(tmp8);
   if (tmp8 === '"Yes"' || tmp8 === '"Yes."' || tmp8 === '"yes"' || tmp8 === '"yes."' ) {  alert('Message Rejected for trolling') ;   modifyMetric('bot2');stateChange("0"); }  else { 

    if (tmp8 === '"No"' || tmp8 === '"No."' || tmp8 ==='"no"' || tmp8 === '"no."' ) { handleAddComment(tmp8 + " bot3- " + tmpbot) } else {handleAddComment('ERROR: ' + tmp8 +  " bot3- " + tmpbot) }

};

  
   })
   .catch((error) => {
	alert('BOT ERROR:' + error.message ) 
  })    
   
};

//alert('askbot-end ' + comment2 )

};

//////////////////////////





async function askbot3(tmpbot) {
 // alert('askbot3');
 // alert('askbot-start: ' +  comment2)


if (comment2 === "") {alert('Message blank') } else {

const genText2 = () => `I want you to tell me if the following message contains gibberish or random letters and symbols.  Answer in one word if it is, yes or no" 

`
;

var messages=[
 {"role": "system", "content": "you are a helpful assistant that protects users against message that hide meaning in gibberish text."},
 {"role": "user", "content": genText2()},
 {"role": "user", "content": comment2},
 
];
//var raw = JSON.stringify({"model": "gpt-3.5-turbo","messages": [{"role": "user", "content": content}],"temperature":0.7});
var raw = JSON.stringify({"model": "gpt-4-0613","messages": messages   ,"temperature":0.0});


 //alert('askbot-messages: ' +  messages[0])

 
//alert('askbot-pre fetch');


//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

 var requestOptions = {
     method: 'POST',
     headers: {
             'Content-Type': 'application/json',
           'Authorization': 'Bearer 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'
             },
     body: raw,
     redirect: 'follow',
  };
  



await fetch("https://api.openai.com/v1/chat/completions", requestOptions)
.then((response) => response.text())
.then((responce) => {
 var obj = JSON.parse(responce);
 var tmp1 = JSON.stringify(obj.choices);
 var tmp2 = tmp1.replace(/\[/g,'');
 var tmp3 = tmp2.replace(/\]/g,'');
 
 var tmp4 = JSON.parse(tmp3);
 var tmp5 = tmp4.message.content;
 var tmp6 = JSON.stringify(tmp5)
 var tmp7 = tmp6.replace(/\\"/g,'');
 var tmp8 = tmp7.replace(/\\n/g,'  ');
 
//alert(tmp8);
 if (tmp8 === '"Yes"' || tmp8 === '"Yes."' || tmp8 === '"yes"' || tmp8 === '"yes."' ) {  alert('Message Rejected for gibberish');   modifyMetric('bot3');stateChange("0");  }  else { 

  if (tmp8 === '"No"' || tmp8 === '"No."' || tmp8 ==='"no"' || tmp8 === '"no."' ) { askbot2(tmp8 + "bot4- " + tmpbot ) } else {askbot2('ERROR: ' + tmp8 ) }

};


 })
 .catch((error) => {
alert('BOT3 ERROR:' + error.message ) 
})    
 
};

//alert('askbot-end ' + comment2 )

};

//////////////////////////





async function askbot4() {
  //alert('askbot4 ' );
 // alert('askbot-start: ' +  comment2)


if (comment2 === "") {alert('Message blank') } else {

  const genText2 = () => `I want you to tell me if the following message is being mocking, overly sarcastic or is being abusive, harassing, or racist.  Answer in one word if it is, yes or no" 
  
  `
  ;

var messages=[
  {"role": "system", "content": "you are a helpful assistant that protects users against being trolled, harassed or spammed."},
  {"role": "user", "content": genText2()},
  {"role": "user", "content": comment2},
 ];

 //var raw = JSON.stringify({"model": "gpt-3.5-turbo","messages": [{"role": "user", "content": content}],"temperature":0.0});

 var raw = JSON.stringify({"model": "gpt-3.5-turbo","messages": messages   ,"temperature":0.0});


//alert('askbot4-messages: ' +  messages[0])


//alert('askbot4-pre fetch');


//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

var requestOptions = {
    method: 'POST',
    headers: {
            'Content-Type': 'application/json',
          'Authorization': 'Bearer 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'
            },
    body: raw,
    redirect: 'follow',
 };
  



  await fetch("https://api.openai.com/v1/chat/completions", requestOptions)
  .then((response) => response.text())
  .then((responce) => {
   var obj = JSON.parse(responce);
   var tmp1 = JSON.stringify(obj.choices);
   var tmp2 = tmp1.replace(/\[/g,'');
   var tmp3 = tmp2.replace(/\]/g,'');
   
   var tmp4 = JSON.parse(tmp3);
   var tmp5 = tmp4.message.content;
   var tmp6 = JSON.stringify(tmp5)
   var tmp7 = tmp6.replace(/\\"/g,'');
   var tmp8 = tmp7.replace(/\\n/g,'  ');
   
  //alert(tmp8);
   if (tmp8 === '"Yes"' || tmp8 === '"Yes."' || tmp8 === '"yes"' || tmp8 === '"yes."' ) { alert('Message Rejected for trolling') ; modifyMetric('bot4'); stateChange("0"); }  else { 
  
    if (tmp8 === '"No"' || tmp8 === '"No."' || tmp8 ==='"no"' || tmp8 === '"no."' ) { askbot3(tmp8) } else {askbot3('ERROR: ' + tmp8 ) }
  
  };


 })
 .catch((error) => {
alert('BOT4 ERROR:' + error.message ) 
})    
 
};

//alert('askbot-end ' + comment2 )

};

//////////////////////////



    async function handleAddComment(tmp) {


       // alert('function HAC');

      
      
    const currentDate = new Date();



    


    try {
     
    

    const result =  await API.graphql({
      query: createComment,
      variables: { 
        input: {
          postID: props.replyId, 
          name: Auth.user.attributes.email,
          description: comment2,
          date: currentDate,
          bot: tmp,
        }
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS',
  });

} catch (error) {
  console.log('error AddComment 472' , error);
};


	  const commentParams = {
	 type: 1, replyId: props.replyId, ccount: props.ccount
	};
    snotify('Comment Created'); 
	
	  //stateChange("1");
	  stateChange2(commentParams);
	  setComment2("");
      
   };


    return (

        




<>






    
     <div style={{minWidth:'10px' , boxShadow:'none'}} >
     
 
		<button className = "addCommentButton"   onClick={handleClickToOpen} >+</button> 
         
            
            
  




        <div stlye={{}}>



           
        <Dialog open={open} onClose={handleToClose}>
        <DialogTitle style={{ borderStyle:'solid', borderTopWidth:'2px', borderLeftWidth:'2px', borderRightWidth:'2px',borderBottomWidth:'0px', borderColor:'grey',  fontSize:'20px' , fontWeight:'bold', color:'#f5f5f5', textAlign:"center", backgroundColor:'#181818'}}>comment</DialogTitle>
        <DialogContent style={{backgroundColor:'#181818', borderStyle:'solid',borderTopWidth:'0px', borderLeftWidth:'2px', borderRightWidth:'2px',borderBottomWidth:'0px', borderColor:'grey'}} >
          <DialogContentText>
          <Flex 
          direction="column"
          textAlign="center"
          alignItems="center"
          color="black"
          minWidth="400px"
          
          minHeight="300px"
          gap="0.5vw"
          paddingTop="1vw"
          fontSize="14px"
          >
            
			<form  onSubmit={handleSubmitComment2}>
    
        <textarea
		
            rows={8}
			cols={50}
           
            value={comment2}
            onChange={(e) => setComment2(e.target.value)}
            type='text'
            name='comment2'
            className='modalInput'
        />
              <button className='modalBtn' style={{backgroundColor:''}}>send</button>
		</form>

          
            
            </Flex>
          </DialogContentText>
          
     
        
        </DialogContent>
        <DialogActions style={{ borderStyle:'solid', borderTopWidth:'0px', borderLeftWidth:'2px', borderRightWidth:'2px',borderBottomWidth:'2px', borderColor:'grey', backgroundColor:'#181818' }}>
		
          <button style={{ fontSize:'16px', borderWidth:'0px', fontWeight:'bold', color:'#f5f5f5' , backgroundColor:'inherit', marginRight:'16px', marginBottom:'10px'}} onClick={handleToClose}>cancel</button> 
          
        </DialogActions>
      </Dialog>





        </div>

        </div>


        </>

    );
};

export default AddComment;