import { Flex, Link, useAuthenticator, useTheme } from "@aws-amplify/ui-react";

export function SignInFooter() {
  const { toResetPassword } = useAuthenticator();
  const { tokens } = useTheme();

  return (
    <Flex 
    fontSize="12px" 
    justifyContent="center" 
    padding={`0 0 ${tokens.space.medium}`}
    marginBottom="12px"
    >
      <span style={{fontSize:'16px'}}><Link onClick={toResetPassword}>Reset your password</Link></span>
    </Flex>
  );
}
