/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUpdate = /* GraphQL */ `
  mutation CreateUpdate(
    $input: CreateUpdateInput!
    $condition: ModelUpdateConditionInput
  ) {
    createUpdate(input: $input, condition: $condition) {
      id
      lastUpdate
      date
      postIDandUser
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateUpdate = /* GraphQL */ `
  mutation UpdateUpdate(
    $input: UpdateUpdateInput!
    $condition: ModelUpdateConditionInput
  ) {
    updateUpdate(input: $input, condition: $condition) {
      id
      lastUpdate
      date
      postIDandUser
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteUpdate = /* GraphQL */ `
  mutation DeleteUpdate(
    $input: DeleteUpdateInput!
    $condition: ModelUpdateConditionInput
  ) {
    deleteUpdate(input: $input, condition: $condition) {
      id
      lastUpdate
      date
      postIDandUser
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMetric = /* GraphQL */ `
  mutation CreateMetric(
    $input: CreateMetricInput!
    $condition: ModelMetricConditionInput
  ) {
    createMetric(input: $input, condition: $condition) {
      id
      bot4
      bot3
      bot2
      bot1
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMetric = /* GraphQL */ `
  mutation UpdateMetric(
    $input: UpdateMetricInput!
    $condition: ModelMetricConditionInput
  ) {
    updateMetric(input: $input, condition: $condition) {
      id
      bot4
      bot3
      bot2
      bot1
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMetric = /* GraphQL */ `
  mutation DeleteMetric(
    $input: DeleteMetricInput!
    $condition: ModelMetricConditionInput
  ) {
    deleteMetric(input: $input, condition: $condition) {
      id
      bot4
      bot3
      bot2
      bot1
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTodo = /* GraphQL */ `
  mutation CreateTodo(
    $input: CreateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    createTodo(input: $input, condition: $condition) {
      id
      name
      description
      date
      type
      bot
      rcount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTodo = /* GraphQL */ `
  mutation UpdateTodo(
    $input: UpdateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    updateTodo(input: $input, condition: $condition) {
      id
      name
      description
      date
      type
      bot
      rcount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTodo = /* GraphQL */ `
  mutation DeleteTodo(
    $input: DeleteTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    deleteTodo(input: $input, condition: $condition) {
      id
      name
      description
      date
      type
      bot
      rcount
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createReply = /* GraphQL */ `
  mutation CreateReply(
    $input: CreateReplyInput!
    $condition: ModelReplyConditionInput
  ) {
    createReply(input: $input, condition: $condition) {
      todoId
      id
      name
      description
      date
      bot
      ccount
      comments {
        items {
          id
          name
          description
          date
          postID
          bot
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateReply = /* GraphQL */ `
  mutation UpdateReply(
    $input: UpdateReplyInput!
    $condition: ModelReplyConditionInput
  ) {
    updateReply(input: $input, condition: $condition) {
      todoId
      id
      name
      description
      date
      bot
      ccount
      comments {
        items {
          id
          name
          description
          date
          postID
          bot
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteReply = /* GraphQL */ `
  mutation DeleteReply(
    $input: DeleteReplyInput!
    $condition: ModelReplyConditionInput
  ) {
    deleteReply(input: $input, condition: $condition) {
      todoId
      id
      name
      description
      date
      bot
      ccount
      comments {
        items {
          id
          name
          description
          date
          postID
          bot
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createComment = /* GraphQL */ `
  mutation CreateComment(
    $input: CreateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    createComment(input: $input, condition: $condition) {
      id
      name
      description
      date
      postID
      bot
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateComment = /* GraphQL */ `
  mutation UpdateComment(
    $input: UpdateCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    updateComment(input: $input, condition: $condition) {
      id
      name
      description
      date
      postID
      bot
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment(
    $input: DeleteCommentInput!
    $condition: ModelCommentConditionInput
  ) {
    deleteComment(input: $input, condition: $condition) {
      id
      name
      description
      date
      postID
      bot
      createdAt
      updatedAt
      __typename
    }
  }
`;
