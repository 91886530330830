import { Heading, useTheme } from "@aws-amplify/ui-react";


export function SignUpHeader() {
  const { tokens } = useTheme();

  return (

    <Heading level={3} padding={`${tokens.space.xl} ${tokens.space.xl} 0`}>
      <span style={{fontSize:'22px'}}>Create New Account</span>
    </Heading>
  );
}
