import React from "react";

import { useNavigate } from "react-router-dom";
import { Amplify} from 'aws-amplify';
//import { withAuthenticator } from '@aws-amplify/ui-react';
import awsExports from '../aws-exports';
Amplify.configure(awsExports);






//function Nav({signOut,user}) {
    function Nav(){

    const navigate = useNavigate();

//    const onNotificationClick = (notification) =>
  //      navigate(notification.cta.data.url);


       // function back() {
        //    navigate(-1)
         //  
         //  };
               
function blank(){
    navigate(`/auth/`);
};




    return (
        <nav className='navbar' >
            <h2>no-trolls <span style={{opacity:'.5'}}>(beta)</span>  
            </h2>
            
            
           
            <div className='navbarRight' style={{color:'#f5f5f5', fontSize:'12px'}}>
            
            chatGPT4.0 self-moderated forum
        
     
          {
           // <button className='homeBtn' onClick={signOut}>Sign out</button> 
          }
          <button className='homeBtn' style={{color:'#f5f5f5', borderRadius:"4px"}} onClick={blank}>sign in</button> 
            </div>
            
            
        </nav>
    );
};

export default Nav;
//export default withAuthenticator(Nav);