import React, { useState} from "react";
//import { useNavigate } from "react-router-dom";
import { API  } from 'aws-amplify'
//import { createTodo, updateTodo, deleteTodo } from '../graphql/mutations'
//import { listTodos } from '../graphql/queries'
import {  deleteComment } from '../graphql/mutations'
//import { listReplies } from '../graphql/queries'



import { toast } from "react-toastify";



function RemoveButtonComment( props)  {



   // const navigate = useNavigate();
    function snotify(note) {toast.success(note);};



    const [state, setState] = useState("--");

    const [state2, setState2] = useState("--");
 

    function stateChange(f) {
        setState(f);
        props.funct(f);
    //   to set loader
  };

  function stateChange2(f) {
    setState2(f);
    props.funct2(f);
//   to set rcount
};




function blank(){};



    async function handleRemoveComment() {
        


        try {
     
    
        const result = await API.graphql({
            query: deleteComment,
            variables: { 
                input: {
                    id: props.commentId,
                  }
            },
            authMode: 'AMAZON_COGNITO_USER_POOLS',
        });
      


          const commentParams = {
            type: -1, replyId: props.replyId, ccount: props.ccount
       };


         snotify('Comment Removed'); 
         stateChange("1");
         stateChange2(commentParams);
        } catch (error) {
            console.log('error AddComment 472' , error);
          };

          
    };






    const handleShowErr = () => {

        alert(props.bot)
        
        
        };


if (props.opt2 ===  "1") {

    if (props.name === props.author) {
        var tmpHide = false;
        var tmpHide2 = true;
        var tmpHide3 = true

    } else if(props.name === "rkz@toughguys.biz" ) {    
        tmpHide = true;
        tmpHide2 = true;
        tmpHide3 = false
    } else {
        tmpHide = true;
        tmpHide2 = false;
        tmpHide3 = true
  
    }

} else {
    tmpHide = true;
    tmpHide2 = false;
    tmpHide3 = true

}



    var tmpColor = "white";
    var tmpErr = "";
    var tmpHideErr = true;
    var tmpColor2 = 'orange';
if ( (props.bot).includes("ERROR:") ) {tmpColor="yellow"; tmpErr = "" ;  tmpHideErr = false} else if(props.name==='rkz@toughguys.biz'){ tmpColor2= '#181818'; tmpHideErr = false};
    




    return (
        <div style={{display:'flex' , flexDirection:'row', minWidth:'10px'}} >
     

            <p hidden = {tmpHide} style={{  color: "#434242" }}>
            <button style={{borderWidth:'0px',backgroundColor:'red', color:'white'}} onClick={handleRemoveComment} >X</button> 
            </p>
            <p hidden={tmpHide2}  style={{ color: "#434242" }}>
            <button  style={{ borderWidth:"0px",     backgroundColor:'inherit', color:'black'}} onClick={blank} >X</button> 
            </p>
            <p hidden = {tmpHide3} style={{  color: "#434242" }}>
            <button style={{borderWidth:'0px',backgroundColor:'grey', color:'white'}} onClick={handleRemoveComment} >X</button> 
            </p>
            <span hidden = {tmpHideErr} style={{backgroundColor:tmpColor }}>{tmpErr} <button  style={{borderWidth:'0px', paddingLeft:'2px', paddingRight:'2px', backgroundColor:tmpColor2, color:'white'}} onClick={handleShowErr} >^</button>  </span> 
        
                </div>
    );
};

export default RemoveButtonComment;