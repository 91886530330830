import { Heading, useTheme } from "@aws-amplify/ui-react";


export function SignInHeader() {
  const { tokens } = useTheme();

  return (


    <Heading level={3} padding={`${tokens.space.xl} ${tokens.space.xl} 0`} >
      <span style={{fontSize:'22px'}}>Sign in to your account</span>
    </Heading>


  );
}
